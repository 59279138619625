import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import {Link} from "react-router-dom";
import { FiChevronUp } from "react-icons/fi";
import HeaderTwo from "../component/header/HeaderTwo";
import FooterTwo from "../component/footer/FooterTwo"
import Brand from "../elements/Brand";
import PortfolioList from "../elements/portfolio/PortfolioList";
import TabTwo from "../elements/tab/TabTwo";
import ContactOne from "../elements/contact/ContactOne";
import Helmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import { videoTagString, VideoTag } from "react-video-tag";
import {GoLinkExternal} from "react-icons/all";

import video from "../assets/images/service/EriksReelVideoShort_2.webm";
import bgImage from "../assets/images/bg/bg-image-24.jpg";

import erikStella from "../assets/images/eriks/erikStella.jpg";

videoTagString({
    src: video,
    poster: bgImage,
});

const SlideList = [
    {
        textPosition: 'text-left',
        category: 'Unreal Engine & Unity Engine Programmer',
        title: 'Erik Anderson',
        description: "",
        description2: "",
        description3: "",
        buttonText: '',
        buttonLink: ''
    }
]
class PersonalPortfolio extends Component{
    constructor() {
        super();
        this.state = {
            isOpen: false,
        };
        this.openModal = this.openModal.bind(this);
    }
    openModal() {
        this.setState({ isOpen: true });
    }
    render(){
        let title = 'About Me',
            description =
                'I am a seasoned software engineer and game developer with 13 years of extensive experience in Unreal Engine and Unity 3D.' +
                ' Throughout my career, I have had the privilege to collaborate with amazing professionals and esteemed companies, enriching my skill set.',
            description2 =
                ' My expertise encompasses Augmented Reality (AR), Virtual Reality (VR), and Cross Reality (XR), with a successful track record of delivering two market-ready games.' +
                ' I specialize in Unreal Engine Blueprints and C++, as well as Unity C#, and I also have proficiency in web development and JavaScript.',
            description3 =
                ' I am highly skilled in the Unreal Engine Animation System, adept in creating pipelines for Procedural Animation, Motion Matching, IK Setups, and Control Rig Setups.' +
                ' Furthermore, I have comprehensive experience in  Motion Capture solutions and creating and integrating backgrounds for Music Videos, including establishing necessary pipelines for smooth production.';
        return(
            <Fragment>
                <Helmet pageTitle="" />

                {/*<HeaderTwo logo="symbol-dark" color="color-black"/>*/}

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value, index) => (
                        <div
                            className="slide slide-style-2 slider-video-bg d-flex align-items-center justify-content-center"
                            key={index}
                            data-black-overlay="6"
                        >
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-8">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ""}
                                            {value.title ? (
                                                <h1 className="title">{value.title}</h1>
                                            ) : (
                                                ""
                                            )}
                                            {value.description ? (
                                                <p className="description">{value.description}</p>
                                            ) : (
                                                ""
                                            )}
                                            {value.description2 ? (
                                                <p className="description2">{value.description2}</p>
                                            ) : (
                                                ""
                                            )}
                                            {value.description3 ? (
                                                <p className="description3">{value.description3}</p>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>

                                    {/* VIDEO BUTTON SECTION */}
                                    {/*<div className="col-lg-4">*/}
                                    {/*    <div className="video-inner">*/}
                                    {/*        <ModalVideo*/}
                                    {/*            channel="youtube"*/}
                                    {/*            isOpen={this.state.isOpen}*/}
                                    {/*            videoId="ZOoVOfieAF8"*/}
                                    {/*            onClose={() => this.setState({ isOpen: false })}*/}
                                    {/*        />*/}
                                    {/*        <button*/}
                                    {/*            className="video-popup theme-color"*/}
                                    {/*            onClick={this.openModal}*/}
                                    {/*        >*/}
                                    {/*            <span className="play-icon"></span>*/}
                                    {/*        </button>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                            <div className="video-background">
                                <VideoTag
                                    autoPlay={`${true}`}
                                    muted={`${true}`}
                                    playsInline={`${true}`}
                                    loop={`${true}`}
                                    src={video}
                                    poster={bgImage}
                                />
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}
                </div>

                {/* Start About Area */}
                <div className="about-area about-position-top pb--120  bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-start">
                                <div className="col-lg-5">
                                    <div className="thumbnail" style={{marginTop: 72}}>
                                        <img className="w-100" src={erikStella} alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner pt--100">
                                        <div className="section-title">
                                            <h2 className="title">{title}</h2>
                                            <p className="description">{description}</p>
                                            <p className="description">{description2}</p>
                                            <p className="description">{description3}</p>
                                        </div>
                                        <br/>
                                        <br/>
                                        <div className="section-title text-center mb--50">
                                            {/*<h2 className="title">Test</h2>*/}
                                            <p className="description">I am available for freelance work. Connect with
                                                me via email:
                                                <a href="mailto:erik@helladecentgames.com"> erik@helladecentgames.com</a>
                                            </p>
                                            <h5>
                                                <a href="assets/images/eriks/ErikAnderson_Resume.pdf"
                                                   download>
                                                    Download Resume Here <GoLinkExternal/>
                                                </a>
                                            </h5>
                                        </div>
                                        <div className="row mt--30">
                                            <TabTwo tabStyle="tab-style--1"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}

                {/* Start Brand Area */}
                <div className="rn-brand-area pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30">
                                    {/*<h2 className="title">Clients</h2>*/}
                                    <h2>Previous Clients</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mt--40 mt_sm--5 mt_md--5">
                                <Brand branstyle="branstyle--2" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start Portfolio Area */}
                <div className="portfolio-area pb--60 bg_color--1">
                    <div className="portfolio-sacousel-inner mb--55 mb_sm--0">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                        <h2 className="title">Projects</h2>
                                        {/*<h3>(ones I'm allowed to talk about)</h3>*/}
                                        {/*<p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>*/}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioList styevariation="text-center mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="9" />
                            </div>

                            {/*<div className="row">*/}
                            {/*    <div className="col-lg-12">*/}
                            {/*        <div className="view-more-btn mt--60 mt_sm--30 text-center">*/}
                            {/*            <a className="rn-button-style--2 btn-solid" href="/blog"><span>View More</span></a>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}

                {/*/!* Start Portfolio Area *!/*/}
                {/*<div className="portfolio-area pb--120 bg_color--1">*/}
                {/*    <ContactOne />*/}
                {/*</div>*/}
                {/*/!* End Portfolio Area *!/*/}

                <FooterTwo />

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}

export default PersonalPortfolio;