import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/FooterTwo";
import {GoLinkExternal} from "react-icons/all";

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/" },
  { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/" },
  { Social: <FaTwitter />, link: "https://twitter.com/" },
];

import portfolio1 from "../assets/images/eriks/McgrawHillAR_3_1230.png";
import portfolio2 from "../assets/images/eriks/McgrawHillAR_2_1280.png";
import portfolio3 from "../assets/images/eriks/McgrawHillAR_1_1280.png";
import portfolio4 from "../assets/images/eriks/McgrawHillAR_4_1230.png";
import relatedImg1 from "../assets/images/portfolio/related-image-01.jpg";
import relatedImg2 from "../assets/images/portfolio/related-image-02.jpg";

class PortfolioDetails7 extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
        <React.Fragment>
          <PageHelmet pageTitle="Portfolio Details" />

          {/*<Header*/}
          {/*  headertransparent="header--transparent"*/}
          {/*  colorblack="color--black"*/}
          {/*  logoname="logo.png"*/}
          {/*/>*/}

          {/* Start Breadcrump Area */}
          <div
              className="rn-page-title-area pt--120 pb--190 bg_image bg_image--9"
              data-black-overlay="7"
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="rn-page-title text-center pt--100">
                    {/*<h2 className="title theme-gradient">*/}
                    {/*  PortFolio1*/}
                    {/*  */}
                    {/*</h2>*/}
                    {/*<p>*/}
                    {/*  Contrary to popular belief, Lorem Ipsum is not simply random*/}
                    {/*  text.{" "}*/}
                    {/*</p>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Breadcrump Area */}

          {/* Start Portfolio Details */}
          <div className="rn-portfolio-details ptb--120 bg_color--1">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="portfolio-details">
                    <div className="inner">
                      <h2><a target="_blank" rel="noopener noreferrer"
                             href="https://www.mheducation.com/prek-12/explore/mcgraw-hill-ar.html">
                        McGraw Hill AR <GoLinkExternal />
                      </a></h2>
                      <h3><a target="_blank" rel="noopener noreferrer"
                             href="https://allthingsmedia.com/">
                        All Things Media <GoLinkExternal />
                      </a></h3>
                      <br/>
                      <p>
                        I served as a key developer of the Unity Development team at All Things Media, contributing to a 
                        contracted project for McGraw Hill and Verizon. Our task was to develop the McGraw Hill AR 
                        application for Apple and Android  platforms. This educational app utilized Augmented Reality 
                        to enhance student learning across various subjects such as math, history, and science.
                      </p>
                      <p>
                        My responsibilities included programming, development, and deployment of six distinct activities 
                        within the application. These activities, which remain available today, are:
                      </p>
                      <p>
                        <li>
                          <ul>
                            Coordinate Planes
                          </ul>
                        </li>
                        <li>
                          <ul>
                            Oregon Trail
                          </ul>
                        </li>
                        <li>
                          <ul>
                            Parthenon
                          </ul>
                        </li>
                        <li>
                          <ul>
                            Lowest Common Multiple
                          </ul>
                        </li>
                        <li>
                          <ul>
                            Trench Ratios
                          </ul>
                        </li>
                        <li>
                          <ul>
                            Silk Road
                          </ul>
                        </li>
                      </p>
                      <p>
                        Through my role, I ensured the successful implementation and functionality of each activity, 
                        contributing to the educational value and user experience of the McGraw Hill AR application.
                      </p>


                      <div className="portfolio-view-list d-flex flex-wrap">
                        <div className="port-view">
                          <span>Role</span>
                          <h5>Unity Programmer / Developer</h5>
                        </div>
                        <div className="port-view">
                          <span>Engine</span>
                          <h5>Unity 3D</h5>
                        </div>
                        <div className="port-view">
                          <span>Project Type</span>
                          <h5>Augmented Reality</h5>
                        </div>
                        <div className="port-view">
                          <span>Device</span>
                          <h5>Mobile (iOS & Android)</h5>
                        </div>
                      </div>


                      {/*<div className="portfolio-share-link mt--20 pb--70 pb_sm--40">*/}
                      {/*  <ul className="social-share rn-lg-size d-flex justify-content-start liststyle mt--15">*/}
                      {/*    {SocialShare.map((val, i) => (*/}
                      {/*        <li key={i}>*/}
                      {/*          <a href={`${val.link}`}>{val.Social}</a>*/}
                      {/*        </li>*/}
                      {/*    ))}*/}
                      {/*  </ul>*/}
                      {/*</div>*/}
                    </div>
                    <br/>
                    <div className="portfolio-thumb-inner">
                      <div className="thumb position-relative mb--30">
                        <img src={portfolio1} alt="Portfolio Images"/>
                        <ModalVideo
                            channel="youtube"
                            isOpen={this.state.isOpen}
                            videoId="qHnPjG3WyQQ&t"
                            onClose={() => this.setState({isOpen: false})}
                        />
                        <button
                            className="video-popup position-top-center"
                            onClick={this.openModal}
                        >
                          <span className="play-icon"></span>
                        </button>
                      </div>

                      <div className="thumb position-relative mb--30">
                        <img src={portfolio3} alt="Portfolio Images"/>
                        <ModalVideo
                            channel="youtube"
                            isOpen={this.state.isOpen}
                            videoId="7sEHMiaWG_I"
                            onClose={() => this.setState({isOpen: false})}
                        />
                        <button
                            className="video-popup position-top-center"
                            onClick={this.openModal}
                        >
                          <span className="play-icon"></span>
                        </button>
                      </div>

                      <div className="thumb mb--30">
                        <img src={portfolio2} alt="Portfolio Images"/>
                      </div>

                      <div className="thumb">
                        <img src={portfolio4} alt="Portfolio Images"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Portfolio Details */}
          
          {/* Start Back To Top */}
          <div className="backto-top">
            <ScrollToTop showUnder={160}>
              <FiChevronUp />
            </ScrollToTop>
          </div>
          {/* End Back To Top */}
          <Footer />
        </React.Fragment>
    );
  }
}
export default PortfolioDetails7;
