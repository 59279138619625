import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {GoLinkExternal} from "react-icons/all";
import certificate1 from "../../assets/images/eriks/CertificateUE51.jpg";

class TabsTwo extends Component {
  render() {
    let tab1 = "Main Skills",
      tab2 = "Awards",
      tab3 = "Experience",
      tab4 = "Education",
      tab5 = "Certificates";
    const { tabStyle } = this.props;
    return (
      <div>
        {/* Start Tabs Area */}
        <div className="tabs-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Tabs>
                  <TabList className={`${tabStyle}`}>
                    <Tab>{tab1}</Tab>
                    <Tab>{tab3}</Tab>
                    <Tab>{tab4}</Tab>
                    <Tab>{tab2}</Tab>
                    <Tab>{tab5}</Tab>
                  </TabList>

                  {/* Main Skills */}
                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <a target="_blank" rel="noopener noreferrer" href="https://www.unrealengine.com/en-US">
                            Unreal Engine <span> - Development/Programming <GoLinkExternal /></span>
                          </a>
                          <ul>
                            <li>Blueprints</li>
                            <li>C++</li>
                            <li>Mixed Reality Pipelines (VR, AR, XR)</li>
                            <li>Animation Scripting</li>
                            <li>Motion Matching</li>
                            <li>IK Setups</li>
                            <li>Procedural Animation</li>
                            <li>Character Controllers</li>
                            <li>Control Rigs</li>
                            <li>Motion Capture Pipelines</li>
                          </ul>
                        </li>
                        <br/>
                        <li>
                          <a target="_blank" rel="noopener noreferrer" href="https://unity.com">
                            Unity Engine <span> - Development/Programming <GoLinkExternal /></span>
                          </a>
                          <ul>
                            <li>C#</li>
                            <li>Mobile Development (iOS, Android)</li>
                            <li>Mixed Reality Pipelines (VR, AR, XR)</li>
                            <li>Animation Scripting</li>
                          </ul>
                        </li>
                        <br/>
                        <li>
                          <a href="/service">
                            Web Development <span> - Scripting/Implementation</span>
                          </a>
                          <ul>
                            <li>Web Design</li>
                            <li>JavaScript</li>
                            <li>React</li>
                          </ul>
                        </li>
                        <br/>
                        <li>
                          <a href="/service">
                            Motion Capture<span> - Pipelines/Implementation</span>
                          </a>
                          <ul>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.movella.com/products/motion-capture">
                              <li>Xsens Motion Capture (Body) <GoLinkExternal /></li>
                            </a>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.rokoko.com/">
                              <li>Rokoko Motion Capture (Body) <GoLinkExternal /></li>
                            </a>
                            <a target="_blank" rel="noopener noreferrer" href="https://optitrack.com/">
                              <li>OptiTrak Motion Capture (Body) <GoLinkExternal /></li>
                            </a>
                            <a target="_blank" rel="noopener noreferrer" href="https://facewaretech.com/">
                              <li>Faceware Facial Motion Capture (Face) <GoLinkExternal /></li>
                            </a>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

   

                  {/* Experience */}
                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://www.framestore.com/">
                            <b>Unreal Engine Developer - Framestore <GoLinkExternal/></b>
                          </a>
                          <span>January 2024 - May 2024</span>
                          <br/>
                          <span>Cleveland, Ohio (Remote)</span>
                          <br/>

                          <span>I completed a 5-month contract focused on an Unreal Engine project aimed at exploring 
                            movement systems for a Framestore IP character in an immersive experience. 
                          </span>
                          <br/>
                          <br/>
                          <span>
                            My key contributions included developing custom setups for three distinct walk systems: 
                            Motion Matching, Procedural Animation, and Traditional Animation.
                          </span>
                          <br/>
                          <br/>
                          <span>
                            Additionally, I implemented custom animation systems on the character to manage Inverse 
                            Kinematics (IK), Foot Sliding, and intricate Control Rig configurations.
                          </span>
                        </li>
                        <br/>
                        <li>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://allthingsmedia.com/">
                            <b>Unity Engine Developer - All Things Media LLC <GoLinkExternal/></b>
                          </a>
                          <span>April 2023 - December 2023</span>
                          <br/>
                          <span>Cleveland, Ohio (Remote)</span>
                          <br/>
                          <span>I served as a key developer of the Unity Development team at All Things Media,
                            contributing to a contracted project for McGraw Hill and Verizon. Our task was to 
                            develop the McGraw Hill AR application for Apple and Android  platforms. 
                            This educational app utilized Augmented Reality to enhance student learning across 
                            various subjects such as math, history, and science.
                          </span>
                          <br/>
                          <br/>
                          <span>
                            This educational app utilized Augmented Reality to enhance student learning across
                            various subjects such as math, history, and science.
                          </span>
                          <br/>
                          <br/>
                          <span>
                            My responsibilities included programming, development, and deployment of six distinct 
                            activities within the application. These activities, which remain available today, are:
                            Coordinate Planes, Oregon Trail, Parthenon, Lowest Common Multiple, Trench, Ratios, and 
                            Silk Road.
                          </span>
                          <br/>
                          <br/>
                          <span>
                            Through my role, I ensured the successful implementation and functionality of each activity, 
                            contributing to the educational value and user experience of the McGraw Hill AR application.
                          </span>
                        </li>
                        <br/>
                        <li>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://vitamotus.com/">
                            <b>UE5 Developer - Vita Motus <GoLinkExternal/></b>
                          </a>
                          <span>January 2023 - April 2023</span>
                          <br/>
                          <span>Cleveland, Ohio (Remote)</span>
                          <br/>
                          <span>I led the creation of a project focused on visualizing lighting for a Show Room & 
                            Banquet Hall slated for construction in Chicago. 
                          </span>
                          <br/>
                          <br/>
                          <span>
                            This involved dynamically controlling movement and lighting effects synced to music across 
                            1,100 lights within the environment.
                          </span>
                        </li>
                        <br/>
                        <li>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://www.forager.earth/">
                            <b>UE5 Engineer - Forager <GoLinkExternal/></b>
                          </a>
                          <span>January 2023 - March 2023</span>
                          <br/>
                          <span>Cleveland, Ohio (Remote)</span>
                          <br/>
                          <span> I contributed to the development of an Interactive VR experience showcasing the first 
                            volumetric time-lapse within a game engine.
                          </span>
                          <br/>
                          <br/>
                          <span>
                            At Forager, I played a pivotal role in setting up several in-engine systems. 
                            This included implementing a robust weather system capable of rapid seasonal transitions, 
                            and designing a dynamic system for generating random mushrooms across various areas.
                          </span>
                          <br/>
                          <br/>
                          <span>
                            The project premiered at events such as SXSW-2023, Siggraph, BFI London Film Festival, and others.
                          </span>
                        </li>
                        <br/>
                        <li>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://www.leftfieldlabs.com/">
                            <b>Unity Developer & Unreal Developer - Left Field Labs <GoLinkExternal/></b>
                          </a>
                          <span>January 2022 - September 2022</span>
                          <br/>
                          <span>Cleveland, Ohio (Remote)</span>
                          <br/>
                          <span>Employed as a Remote Unity Developer at Left Field Labs, based in Los Angeles, CA, 
                            servicing high-profile clients including Google, Verizon, and Sony Pictures. 
                          </span>
                          <br/>
                          <br/>
                          <span>
                            Specialized in developing VR and mobile applications, with additional 
                            contributions to Unreal Engine VR projects.
                          </span>
                        </li>
                        <br/>
                        <li>
                          <a target="_blank" rel="noopener noreferrer"
                             href="http://www.sketchbot.tv/museum-of-untold-possibilities">
                            <b>UE4 Lead Programmer - Museum of Untold Possibilities <GoLinkExternal/></b>
                          </a>
                          <span>June 2021 - December 2021</span>
                          <br/>
                          <span>Cleveland, Ohio (Remote)</span>
                          <br/>
                          <span>Served as Development Lead and for the Epic Mega Grants  funded VR project: Museum of 
                            Untold Possibilities.</span>
                          <br/>
                          <br/>
                          <span>
                            Led the creation of a VR character from inception, designed for exploration and 
                            interaction within the museum environment.
                          </span>
                          <br/>
                          <br/>
                          <span>
                            Responsibilities were to create an entire VR-Character from the ground up that was 
                            designed to explore the Museum and interact with the art, other museum-goers, and 
                            staff who worked there.
                          </span>
                        </li>
                        <br/>
                        <li>
                          <a target="_blank" rel="noopener noreferrer"
                             href="http://newreality.co/">
                            <b>UE4 Lead Programmer - New Reality Co. <GoLinkExternal/></b>
                          </a>
                          <span>September 2020 - May 2021</span>
                          <br/>
                          <span>Cleveland, Ohio (Remote)</span>
                          <br/>
                          <span>Led Unreal Engine 4 programming and development for an Epic MegaGrant project focused 
                            on rainforest preservation using XR devices.</span>
                        </li>
                        <br/>
                        <li>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://www.electricplayhouse.com/">
                            <b>UE4 Lead Programmer - Electric Playhouse <GoLinkExternal/></b>
                          </a>
                          <span>June 2020 - August 2020</span>
                          <br/>
                          <span>Cleveland, Ohio (Remote)</span>
                          <br/>
                          <span>
                            Developed a complete project titled 'Bisti Beast' for the Albuquerque Museum of Natural 
                            History, focusing on a dinosaur exhibit. 
                          </span>
                          <br/>
                          <br/>
                          <span>
                            It utilized Microsoft Kinect for real-time user tracking in front of a projected landscape 
                            featuring interactive roaming dinosaurs.
                          </span>
                          <br/>
                          <br/>
                          <span>
                            I also implemented an additional panel allowing users to select dinosaurs for interaction, 
                            integrating OSC signals to communicate with the Unreal Engine application.
                          </span>
                        </li>
                        <br/>
                        <li>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://meowwolf.com/">
                            <b>XR Programmer & Developer - Meow Wolf <GoLinkExternal/></b>
                          </a>
                          <span>June 2018 - April 2020</span>
                          <br/>
                          <span>Santa Fe, New Mexico</span>
                          <br/>
                          <span>Worked on both Virtual Reality and Augmented Reality projects for the future exhibits 
                            at Meow Wolf’s locations in Las Vegas and Denver using both Unreal Engine and Unity Engine. </span>
                          <ul style={{listStyleType: "disc"}}>
                            <li style={{marginLeft: 20}}>
                              <a target="_blank" rel="noopener noreferrer"
                                 href="https://vimeo.com/260506208">
                                <b>The Atrium <GoLinkExternal/></b>
                              </a>
                              <span>Designed and implemented the rig for an IK-controlled VR Hamster, serving as the 
                                main character. Contributed to the development of additional 
                                scripts and AI functionalities.</span>
                            </li>
                            <li style={{marginLeft: 20}}>
                              <a target="_blank" rel="noopener noreferrer"
                                 href="https://www.forbes.com/sites/jessedamiani/2020/02/19/the-top-35-xr-installations-of-2019/?sh=267d81a3ca08">
                                <b>The Garage <GoLinkExternal/></b>
                              </a>
                              <span>Developed and helped to create all four of the installations within the Garage AR 
                                experience utilizing the Magic Leap headset.</span>
                            </li>
                            <li style={{marginLeft: 20}}>
                              <a target="_blank" rel="noopener noreferrer"
                                 href="https://www.youtube.com/watch?v=kl-xeN_PaI4">
                                <b>The Navigator <GoLinkExternal/></b>
                              </a>
                              <span>Collaborated on developing the infrastructure and gameplay mechanics for the 
                                Navigator AR experience, utilizing the Magic Leap headset.</span>
                            </li>
                          </ul>
                        </li>
                        <br/>
                        <li>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://www.treeofficial.com/">
                            <b>Lead Engine Programmer - TreeVR <GoLinkExternal/></b>
                          </a>
                          <span>October 2016 - February 2017</span>
                          <br/>
                          <span>Santa Cruz, CA (Remote)</span>
                          <br/>

                          <span>Engineered IK solutions within Unreal Engine to immerse VR players in the role of the 
                            'tree' character, focusing on creating a lifelike experience. Managed environment design 
                            and burning effects as well as some other AI functions. 
                          </span>
                          <br/>
                          <br/>
                          <span>
                            Orchestrated collaboration among four international art and effects teams within a 
                            unified UE4 project with rigorous source control management. 
                          </span>
                          <br/>
                          <br/>
                          <span>
                            Provided on-site technical support and setup for the TreeVR experience 
                            showcased at Sundance Film Festival 2017.
                          </span>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://www.youtube.com/watch?v=ERffRXjTAqM">
                            <b>Trailer <GoLinkExternal/></b>
                          </a>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://www.youtube.com/watch?v=QLpDeZhObAs">
                            <b>Full Play-Through <GoLinkExternal/></b>
                          </a>
                        </li>
                        <br/>
                        <li>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://rolfes.team/">
                            <b>Lead Engine Programmer - Team Rolfes <GoLinkExternal/></b>
                          </a>
                          <span>August 2015 - January 2019</span>
                          <br/>
                          <span>Santa Cruz, CA (Remote)</span>
                          <br/>
                          <span>Conceptualized and implemented groundbreaking applications of Unreal Engine and visual 
                            effects for music videos and gaming content. </span>
                          <br/>
                          <br/>
                          <span>
                            Collaborated closely with directors to pioneer innovative approaches in leveraging music 
                            and the engine for creative productions.
                          </span>
                          <br/>
                          <br/>
                          <span>
                            Designed and deployed VR environments for live events and video streams, providing 
                            crucial technical support to teams throughout production.

                          </span>
                          <ul style={{listStyleType: "disc"}}>
                            <li style={{marginLeft: 20}}>
                              <a target="_blank" rel="noopener noreferrer"
                                 href="https://en.wikipedia.org/wiki/Super_Deluxe">
                                <b>SuperDeluxe Live <GoLinkExternal/></b>
                              </a>
                            </li>
                            <li style={{marginLeft: 20}}>
                              <a target="_blank" rel="noopener noreferrer"
                                 href="https://www.youtube.com/watch?v=MZBRWqxmAjw">
                                <b>Rihanna Live VMA 2016 Background Video <GoLinkExternal/></b>
                              </a>
                            </li>
                            <li style={{marginLeft: 20}}>
                              <a target="_blank" rel="noopener noreferrer"
                                 href="https://www.youtube.com/watch?v=02k126v3Zu4">
                                <b>Amnesia Scanner - Chingy (featured on Adult Swim - Off The Air) <GoLinkExternal/></b>
                              </a>
                            </li>
                            <li style={{marginLeft: 20}}>
                              <a target="_blank" rel="noopener noreferrer"
                                 href="https://www.youtube.com/watch?v=u6dW0g4WgY0">
                                <b>Danny L Harle - Ashes of Love ft. Caroline Polachek <GoLinkExternal/></b>
                              </a>
                            </li>
                          </ul>
                        </li>
                        <br/>
                        <li>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://www.colum.edu/academics/media-arts/interactive-arts-and-media/">
                            <b>Teachers Aid (C#, C++) - Columbia College in Chicago - Interactive Arts & Media
                              Department <GoLinkExternal/></b>
                          </a>
                          <span>September 2009 - September 2011</span>
                          <br/>
                          <span>Chicago, IL </span>
                          <br/>
                          <span>
                            Assisted faculty as a Teacher's Aid and programming tutor within the Interactive Arts and 
                            Media department at Columbia College Chicago. 
                          </span>
                          <br/>
                          <br/>
                          <span>
                            Provided comprehensive tutoring in C#, C++, and general Object-Oriented Programming 
                            techniques both in and outside the computer lab.
                          </span>
                          <br/>
                          <br/>
                          <span>
                            Managed maintenance and upkeep of school machines in the lab, ensuring optimal 
                            functionality for students and faculty.
                          </span>
                        </li>
                        <br/>
                      </ul>
                    </div>
                  </TabPanel>

                  {/* Education */}
                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://www.colum.edu/academics/programs/programming">
                            Bachelor of Arts - Game Engine Programming <GoLinkExternal/>
                          </a>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://www.colum.edu/">
                            Columbia College in Chicago <GoLinkExternal/>
                          </a>
                          <span>Chicago, IL</span>
                          <br/>
                          <span>2012</span>
                        </li>
                        <br/>
                        <li>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://www.dvc.edu/">
                            Diablo Valley Community College <GoLinkExternal/>
                          </a>
                          <span>San Ramon, CA</span>
                          <br/>
                          <span>2005-2006</span>
                        </li>
                        <br/>
                        <li>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://www.dvc.edu/">
                            Monte Vista High School <GoLinkExternal/>
                          </a>
                          <span>Danville, CA</span>
                          <br/>
                          <span>2006</span>
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                  {/* Awards */}
                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://theadvancedimagingsociety.com/creative-arts-lumiere-awards/">
                            Lumiere Award <GoLinkExternal/>
                          </a>
                          <span>"Tree" - New Reality Company - 2018</span>
                          BEST VR LOCATION BASED ANIMATED SHORT
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://variety.com/2018/artisans/awards/blade-runner-2049-coco-darla-anderson-pixar-take-major-prizes-at-lumiere-awards-1202696141/">
                            Variety Magazine Article <GoLinkExternal/>
                          </a>
                        </li>

                      </ul>
                    </div>
                  </TabPanel>
                  
                  {/* Certificates */}
                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://www.udemy.com/certificate/UC-4d343f5e-c55a-41d5-8461-09f679e6fa8d/">
                            Unreal Engine 5 C++ Developer:Learn C++ & Make Video Games<GoLinkExternal/>
                          </a>
                          <span>Udemy Course</span>
                          2023
                          <br/>
                          <img className="w-50" src={certificate1} alt="certificate image"/>
                        </li>

                      </ul>
                    </div>
                  </TabPanel>
                  
                </Tabs>
              </div>
            </div>
          </div>
        </div>
        {/* End Tabs Area */}
      </div>
    );
  }
}

export default TabsTwo;
