import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/FooterTwo";
import {GoLinkExternal} from "react-icons/all";

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/" },
  { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/" },
  { Social: <FaTwitter />, link: "https://twitter.com/" },
];

import portfolio1 from "../assets/images/eriks/Mushroom_1_1230.jpg";
import portfolio2 from "../assets/images/eriks/Mushroom_2_1230.jpg";
import portfolio3 from "../assets/images/eriks/Forager_UnrealFest_Image_1230.png";
import portfolio4 from "../assets/images/eriks/Forager1_1280.jpg";
import relatedImg1 from "../assets/images/portfolio/related-image-01.jpg";
import relatedImg2 from "../assets/images/portfolio/related-image-02.jpg";

class PortfolioDetails6 extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
        <React.Fragment>
          <PageHelmet pageTitle="Portfolio Details" />

          {/*<Header*/}
          {/*  headertransparent="header--transparent"*/}
          {/*  colorblack="color--black"*/}
          {/*  logoname="logo.png"*/}
          {/*/>*/}

          {/* Start Breadcrump Area */}
          <div
              className="rn-page-title-area pt--120 pb--190 bg_image bg_image--8"
              data-black-overlay="7"
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="rn-page-title text-center pt--100">
                    {/*<h2 className="title theme-gradient">*/}
                    {/*  PortFolio1*/}
                    {/*  */}
                    {/*</h2>*/}
                    {/*<p>*/}
                    {/*  Contrary to popular belief, Lorem Ipsum is not simply random*/}
                    {/*  text.{" "}*/}
                    {/*</p>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Breadcrump Area */}

          {/* Start Portfolio Details */}
          <div className="rn-portfolio-details ptb--120 bg_color--1">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="portfolio-details">
                    <div className="inner">
                      <h2><a target="_blank" rel="noopener noreferrer"
                             href="https://www.forager.earth/">
                        Forager <GoLinkExternal />
                      </a></h2>
                      <br/>
                      <p>
                        I contributed to the development of an Interactive VR experience showcasing the first volumetric time-lapse within a game engine.
                        At Forager, I played a pivotal role in setting up several in-engine systems. This included implementing a robust weather system
                        capable of rapid seasonal transitions, and designing a dynamic system for generating random mushrooms across various areas.
                      </p>
                      <p>
                        The project premiered at events such as
                        <a target="_blank" rel="noopener noreferrer"
                           href="https://schedule.sxsw.com/2023/films/2079253">
                          SXSW-2023 <GoLinkExternal/>
                        </a>
                        , Siggraph, BFI London Film Festival, and others.
                      </p>

                      <div className="portfolio-view-list d-flex flex-wrap">
                        <div className="port-view">
                          <span>Role</span>
                          <h5>Unreal Programmer / Developer</h5>
                        </div>

                        <div className="port-view">
                          <span>Engine</span>
                          <h5>Unreal Engine 5</h5>
                        </div>

                        <div className="port-view">
                          <span>Project Type</span>
                          <h5>Virtual Reality</h5>
                        </div>

                        <div className="port-view">
                          <span>Device</span>
                          <h5>Oculus Quest 2</h5>
                        </div>
                      </div>
                      {/*<div className="portfolio-share-link mt--20 pb--70 pb_sm--40">*/}
                      {/*  <ul className="social-share rn-lg-size d-flex justify-content-start liststyle mt--15">*/}
                      {/*    {SocialShare.map((val, i) => (*/}
                      {/*        <li key={i}>*/}
                      {/*          <a href={`${val.link}`}>{val.Social}</a>*/}
                      {/*        </li>*/}
                      {/*    ))}*/}
                      {/*  </ul>*/}
                      {/*</div>*/}
                    </div>

                    <div className="portfolio-thumb-inner">
                      <div className="thumb position-relative mb--30">
                        <img src={portfolio4} alt="Portfolio Images"/>
                        <ModalVideo
                            channel="youtube"
                            isOpen={this.state.isOpen}
                            videoId="0CW556Q"
                            onClose={() => this.setState({ isOpen: false })}
                            />
                            <button
                                className="video-popup position-top-center"
                                onClick={this.openModal}
                            >
                              <span className="play-icon"></span>
                            </button>
                          </div>
                        <div className="thumb position-relative mb--30">
                          <img src={portfolio3} alt="Portfolio Images" />
                          <ModalVideo
                              channel="youtube"
                              isOpen={this.state.isOpen}
                              videoId="S02vuKShEts"
                              onClose={() => this.setState({ isOpen: false })}
                          />
                          <button
                              className="video-popup position-top-center"
                              onClick={this.openModal}
                          >
                            <span className="play-icon"></span>
                          </button>
                        </div>

                      <div className="thumb mb--30">
                        <img src={portfolio2} alt="Portfolio Images" />
                      </div>

                      <div className="thumb">
                        <img src={portfolio1} alt="Portfolio Images" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Portfolio Details */}
          
          {/* Start Back To Top */}
          <div className="backto-top">
            <ScrollToTop showUnder={160}>
              <FiChevronUp />
            </ScrollToTop>
          </div>
          {/* End Back To Top */}

          <Footer />
        </React.Fragment>
    );
  }
}
export default PortfolioDetails6;
