import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/FooterTwo";
import {GoLinkExternal} from "react-icons/all";

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/" },
  { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/" },
  { Social: <FaTwitter />, link: "https://twitter.com/" },
];

import portfolio1 from "../assets/images/eriks/Tree_Trees_1280.jpg";
import portfolio2 from "../assets/images/portfolio/portfolio-big-02.jpg";
import portfolio3 from "../assets/images/eriks/Tree_Bird.jpg";
import portfolio5 from "../assets/images/eriks/Tree-02-Still_1280.jpg";
import relatedImg1 from "../assets/images/eriks/Tree_Trees_1280.jpg";
import relatedImg2 from "../assets/images/eriks/Tree_Bird.jpg";
// TREE VR DETAILS
class PortfolioDetails0 extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Portfolio Details" />

        {/*<Header*/}
        {/*  headertransparent="header--transparent"*/}
        {/*  colorblack="color--black"*/}
        {/*  logoname="logo.png"*/}
        {/*/>*/}

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--11"
          data-black-overlay="7"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  {/*<h2 className="title theme-gradient">*/}
                  {/*  TREE VR*/}
                  {/*</h2>*/}
                  {/*<p>*/}
                  {/*  Contrary to popular belief, Lorem Ipsum is not simply random*/}
                  {/*  text.{" "}*/}
                  {/*</p>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Portfolio Details */}
        <div className="rn-portfolio-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="portfolio-details">
                  <div className="inner">
                    <h2>
                      <a target="_blank" rel="noopener noreferrer"
                         href="https://www.treeofficial.com/">
                        TREE VR <GoLinkExternal />
                      </a>
                    </h2>
                    <br/>
                    <p>
                      Engineered IK solutions within Unreal Engine to immerse VR players in the role of the 'tree' 
                      character, focusing on creating a lifelike experience. Managed environment design and burning 
                      effects as well as some other AI functions.
                    </p>
                    <p>
                      Orchestrated collaboration among four international art and effects teams within a unified UE4 
                      project with rigorous source control management.
                    </p>
                    <p>
                      Provided on-site technical support and setup for the TreeVR experience showcased at Sundance 
                      Film Festival 2017.
                    </p>
                    <br/>
                    <br/>
                    <h3>
                      <a target="_blank" rel="noopener noreferrer"
                         href="https://www.viveport.com/apps/3c7e8f90-047b-4c87-a313-d7cdecb5f0a5?hl=en-US">
                        Available for HTC Vive <GoLinkExternal />
                      </a>
                    </h3>


                    <h4>Press</h4>
                    <li>
                      <ul>
                        <h5>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://www.nytimes.com/2017/01/10/movies/at-sundance-the-theme-is-climate-change.html">
                            New York Times <GoLinkExternal />
                          </a>
                        </h5>
                      </ul>
                    </li>
                    <li>
                      <ul>
                        <h5>
                          <a target="_blank" rel="noopener noreferrer"
                             href="http://curious.kcrw.com/2016/12/the-future-of-the-future-of-entertainment">
                            KCRW <GoLinkExternal />
                          </a>
                        </h5>
                      </ul>
                    </li>
                    <li>
                      <ul>
                        <h5>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://techcrunch.com/2017/01/20/sundance-new-frontier/">
                            Tech Crunch <GoLinkExternal />
                          </a>
                        </h5>
                      </ul>
                    </li>
                    <li>
                      <ul>
                        <h5>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://www.artslant.com/ny/articles/show/47316-with-climate-under-threat-artists-look-to-virtual-reality">
                            ArtSlant <GoLinkExternal />
                          </a>
                        </h5>
                      </ul>
                    </li>
                    <li>
                      <ul>
                        <h5>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://observer.com/2017/04/tribeca-film-festival-virtual-reality/">
                            The Observer <GoLinkExternal />
                          </a>
                        </h5>
                      </ul>
                    </li>
                    <li>
                      <ul>
                        <h5>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://www.theverge.com/2017/1/26/14396976/best-vr-sundance-film-festival-2017">
                            The Verge <GoLinkExternal />
                          </a>
                        </h5>
                      </ul>
                    </li>
                    <li>
                      <ul>
                        <h5>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://qz.com/891199/sundance-film-festivals-new-frontier-selection-uses-vr-ar-and-other-mediums-to-make-the-audiences-bodies-the-screen">
                            Quartz Media <GoLinkExternal />
                          </a>
                        </h5>
                      </ul>
                    </li>
                    <li>
                      <ul>
                        <h5>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://www.latimes.com/entertainment/movies/moviesnow/la-ca-mn-vr-future-20170208-story.html">
                            LA Times <GoLinkExternal />
                          </a>
                        </h5>
                      </ul>
                    </li>
                    <li>
                      <ul>
                        <h5>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://www.documentary.org/online-feature/vr-and-ar-take-center-stage-sundances-new-frontier">
                            International Documentary Association <GoLinkExternal />
                          </a>
                        </h5>
                      </ul>
                    </li>
                    <li>
                      <ul>
                        <h5>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://www.media.mit.edu/projects/tree/overview/">
                            MIT MEDIA LAB <GoLinkExternal />
                          </a>
                        </h5>
                      </ul>
                    </li>
                      <li>
                        <ul>
                          <h5>
                            <a target="_blank" rel="noopener noreferrer"
                               href="https://filmmakermagazine.com/101293-an-interactive-and-realistic-amazon-environment-directors-milica-zec-and-winslow-porter-tree/#.WIZKXIFHaEd">
                              Film Maker Magazine <GoLinkExternal />
                            </a>
                          </h5>
                        </ul>
                      </li>
                        <br/>

                    <div className="portfolio-view-list d-flex flex-wrap">
                      <div className="port-view">
                        <span>Role</span>
                        <h5>Lead Engine Programmer</h5>
                      </div>

                      <div className="port-view">
                        <span>Engine</span>
                        <h5>Unreal Engine 4</h5>
                      </div>

                      <div className="port-view">
                        <span>Project Type</span>
                        <h5>Virtual Reality</h5>
                      </div>

                      <div className="port-view">
                        <span>Device</span>
                        <h5>HTC Vive & Oculus Rift</h5>
                      </div>
                    </div>

                    {/*<div className="portfolio-share-link mt--20 pb--70 pb_sm--40">*/}
                    {/*  <ul className="social-share rn-lg-size d-flex justify-content-start liststyle mt--15">*/}
                        {/*    {SocialShare.map((val, i) => (*/}
                        {/*      <li key={i}>*/}
                        {/*        <a href={`${val.link}`}>{val.Social}</a>*/}
                        {/*      </li>*/}
                        {/*    ))}*/}
                        {/*  </ul>*/}
                        {/*</div>*/}
                  </div>
                  <br/>
                  <div className="portfolio-thumb-inner">
                    <div className="thumb position-relative mb--30">
                      <img src={portfolio5} alt="Portfolio Images" />
                      <ModalVideo
                        channel="youtube"
                        isOpen={this.state.isOpen}
                        videoId="ERffRXjTAqM"
                        onClose={() => this.setState({ isOpen: false })}
                      />
                      <button
                        className="video-popup position-top-center"
                        onClick={this.openModal}
                      >
                        <span className="play-icon"></span>
                      </button>
                    </div>

                    <div className="thumb mb--30">
                      <img src={portfolio1} alt="Portfolio Images" />
                    </div>

                    <div className="thumb">
                      <img src={portfolio3} alt="Portfolio Images" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Portfolio Details */}


        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default PortfolioDetails0;
