import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import {GoLinkExternal} from "react-icons/all";

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/" },
  { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/" },
  { Social: <FaTwitter />, link: "https://twitter.com/" },
];

import portfolio1 from "../assets/images/portfolio/portfolio-big-01.jpg";
import portfolio2 from "../assets/images/portfolio/portfolio-big-02.jpg";
import portfolio3 from "../assets/images/portfolio/portfolio-big-03.jpg";
import relatedImg1 from "../assets/images/portfolio/related-image-01.jpg";
import relatedImg2 from "../assets/images/portfolio/related-image-02.jpg";

class PortfolioDetails8 extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
        <React.Fragment>
          <PageHelmet pageTitle="Portfolio Details" />

          {/*<Header*/}
          {/*  headertransparent="header--transparent"*/}
          {/*  colorblack="color--black"*/}
          {/*  logoname="logo.png"*/}
          {/*/>*/}

          {/* Start Breadcrump Area */}
          <div
              className="rn-page-title-area pt--120 pb--190 bg_image bg_image--4"
              data-black-overlay="7"
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="rn-page-title text-center pt--100">
                    {/*<h2 className="title theme-gradient">*/}
                    {/*  PortFolio1*/}
                    {/*  */}
                    {/*</h2>*/}
                    {/*<p>*/}
                    {/*  Contrary to popular belief, Lorem Ipsum is not simply random*/}
                    {/*  text.{" "}*/}
                    {/*</p>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Breadcrump Area */}

          {/* Start Portfolio Details */}
          <div className="rn-portfolio-details ptb--120 bg_color--1">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="portfolio-details">
                    <div className="inner">
                      <h2>Trydo</h2>
                      <p className="subtitle">
                        There are many variations of passages of Lorem Ipsum
                        available, but the majority have suffered alteration in
                        some form.
                      </p>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore magna
                        aliqua. Quis ipsum suspendisse ultrices gravida. Risus
                        commod viverra maecenas accumsan lacus vel facilisis. ut
                        labore et dolore magna aliqua.{" "}
                      </p>

                      <div className="portfolio-view-list d-flex flex-wrap">
                        <div className="port-view">
                          <span>Engine</span>
                          <h4>Unreal Engine 4</h4>
                        </div>

                        <div className="port-view">
                          <span>Project Type</span>
                          <h4>Virtual Reality</h4>
                        </div>

                        {/*<div className="port-view">*/}
                        {/*  <span>Program</span>*/}
                        {/*  <h4>View Project</h4>*/}
                        {/*</div>*/}
                      </div>
                      {/*<div className="portfolio-share-link mt--20 pb--70 pb_sm--40">*/}
                      {/*  <ul className="social-share rn-lg-size d-flex justify-content-start liststyle mt--15">*/}
                      {/*    {SocialShare.map((val, i) => (*/}
                      {/*        <li key={i}>*/}
                      {/*          <a href={`${val.link}`}>{val.Social}</a>*/}
                      {/*        </li>*/}
                      {/*    ))}*/}
                      {/*  </ul>*/}
                      {/*</div>*/}
                    </div>
                    <div className="portfolio-thumb-inner">
                      <div className="thumb position-relative mb--30">
                        <img src={portfolio3} alt="Portfolio Images" />
                        <ModalVideo
                            channel="youtube"
                            isOpen={this.state.isOpen}
                            videoId="ZOoVOfieAF8"
                            onClose={() => this.setState({ isOpen: false })}
                        />
                        <button
                            className="video-popup position-top-center"
                            onClick={this.openModal}
                        >
                          <span className="play-icon"></span>
                        </button>
                      </div>

                      <div className="thumb mb--30">
                        <img src={portfolio2} alt="Portfolio Images" />
                      </div>

                      <div className="thumb">
                        <img src={portfolio1} alt="Portfolio Images" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Portfolio Details */}

          {/* Start Back To Top */}
          <div className="backto-top">
            <ScrollToTop showUnder={160}>
              <FiChevronUp />
            </ScrollToTop>
          </div>
          {/* End Back To Top */}

          <Footer />
        </React.Fragment>
    );
  }
}
export default PortfolioDetails8;
