import React, { Component } from "react";
import {Link} from "react-router-dom";

const PortfolioListContent = [
    {
        image: 'image-1',
        category: 'Virtual Reality',
        title: 'Tree     VR',
        inner: 'Tree VR Test Text'
    },
    {
        image: 'image-2',
        category: 'Virtual Reality',
        title: 'The Atrium'
    },
    {
        image: 'image-3',
        category: 'XR',
        title: 'The Garage'
    },
    {
        image: 'image-4',
        category: 'Kinect 2',
        title: 'Bisti Beast'
    },
    {
        image: 'image-5',
        category: 'XR',
        title: 'The Navigator'
    },
    {
        image: 'image-6',
        category: 'Virtual Reality',
        title: 'Museum of Untold Possibilities'
    },
    {
        image: 'image-7',
        category: 'Virtual Reality',
        title: 'Forager'
    },
    {
        image: 'image-8',
        category: 'Augmented Reality',
        title: 'McGrawHill AR'
    }
]

class PortfolioList extends Component{
    render(){
        const {column , styevariation } = this.props;
        const list = PortfolioListContent.slice(0 , this.props.item);
        return(
            <React.Fragment> 
                {list.reverse().map((value , index, array) => (
                    <div className={`${column}`} key={array.length - index - 1}>
                        <div className={`portfolio ${styevariation}`}>
                            <div className="thumbnail-inner">
                                <div className={`thumbnail ${value.image}`}></div>
                                <div className={`bg-blr-image ${value.image}`}></div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <p>{value.category}</p>
                                    <h4><a href={`/portfolio-details-${array.length - index - 1}`}>{value.title}</a></h4>
                                    <div className="portfolio-button">
                                        <a className="rn-btn" href={`/portfolio-details-${array.length - index - 1}`}>View Details</a>
                                    </div>
                                </div>
                            </div>
                            <Link className="link-overlay" to={`/portfolio-details-${array.length - index - 1}`}></Link>
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioList;