import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/FooterTwo";
import {GoLinkExternal} from "react-icons/all";

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/" },
  { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/" },
  { Social: <FaTwitter />, link: "https://twitter.com/" },
];

import portfolio1 from "../assets/images/eriks/Atrium_VRPlayer.jpeg";
import portfolio2 from "../assets/images/eriks/atrium_Legs.jpg";
import portfolio3 from "../assets/images/eriks/MW_TheAtrium_1230.jpg";
import relatedImg1 from "../assets/images/portfolio/related-image-01.jpg";
import relatedImg2 from "../assets/images/portfolio/related-image-02.jpg";

class PortfolioDetails1 extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Portfolio Details" />

        {/*<Header*/}
        {/*  headertransparent="header--transparent"*/}
        {/*  colorblack="color--black"*/}
        {/*  logoname="logo.png"*/}
        {/*/>*/}

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"
          data-black-overlay="7"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  {/*<h2 className="title theme-gradient">*/}
                  {/*  PortFolio1*/}
                  {/*  */}
                  {/*</h2>*/}
                  {/*<p>*/}
                  {/*  Contrary to popular belief, Lorem Ipsum is not simply random*/}
                  {/*  text.{" "}*/}
                  {/*</p>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Portfolio Details */}
        <div className="rn-portfolio-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="portfolio-details">
                  <div className="inner">
                    <h2>The Atrium</h2>
                    <h3>
                      <a target="_blank" rel="noopener noreferrer"
                         href="https://www.facebook.com/watch/?v=1405487929556529">
                        VIDEO LINK HERE <GoLinkExternal />
                      </a>
                    </h3>
                    <br/>
                    <p>
                      Designed and implemented the rig for an IK-controlled VR Hamster, serving as the main character. 
                    </p>
                    <p>
                      Contributed to the development of additional scripts and AI functionalities.
                    </p>
                    <br/>
                    <br/>

                    <h4>Press</h4>
                    <li>
                      <ul>
                        <h5>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://www.krqe.com/news/meow-wolf-set-to-participate-in-sxsw-festival/amp/">
                            KRQE <GoLinkExternal />
                          </a>
                        </h5>
                      </ul>
                    </li>
                    <li>
                      <ul>
                        <h5>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://www.austinchronicle.com/screens/2018-03-09/meow-wolf-pounces-into-the-sxsw-fray/">
                            The Austin Chronicle <GoLinkExternal />
                          </a>
                        </h5>
                      </ul>
                    </li>
                    <li>
                      <ul>
                        <h5>
                          <a target="_blank" rel="noopener noreferrer"
                             href="https://medium.com/@willhsu7/vr-ar-learnings-in-sxsw-2018-ebc1003243bd">
                            Medium <GoLinkExternal />
                          </a>
                        </h5>
                      </ul>
                    </li>
                    <br/>
                    <div className="portfolio-view-list d-flex flex-wrap">
                      <div className="port-view">
                        <span>Role</span>
                        <h5>XR Programmer & Developer</h5>
                      </div>

                      <div className="port-view">
                        <span>Engine</span>
                        <h5>Unreal Engine 4</h5>
                      </div>

                      <div className="port-view">
                        <span>Project Type</span>
                        <h5>Virtual Reality</h5>
                      </div>

                      <div className="port-view">
                        <span>Device</span>
                        <h5>HTC VIVE</h5>
                      </div>
                    </div>
                    {/*<div className="portfolio-share-link mt--20 pb--70 pb_sm--40">*/}
                    {/*  <ul className="social-share rn-lg-size d-flex justify-content-start liststyle mt--15">*/}
                    {/*    {SocialShare.map((val, i) => (*/}
                    {/*        <li key={i}>*/}
                    {/*          <a href={`${val.link}`}>{val.Social}</a>*/}
                    {/*        </li>*/}
                    {/*    ))}*/}
                    {/*  </ul>*/}
                    {/*</div>*/}
                  </div>
                  <br/>
                  <div className="portfolio-thumb-inner">
                    <div className="thumb position-relative mb--30">
                      <img src={portfolio3} alt="Portfolio Images"/>
                      <ModalVideo
                          channel="youtube"
                          isOpen={this.state.isOpen}
                          videoId="hrvcWRy3t08"
                          onClose={() => this.setState({isOpen: false})}
                      />
                      <button
                          className="video-popup position-top-center"
                          onClick={this.openModal}
                      >
                        <span className="play-icon"></span>
                      </button>
                    </div>

                    <div className="thumb mb--30">
                      <img src={portfolio2} alt="Portfolio Images"/>
                    </div>

                    <div className="thumb">
                      <img src={portfolio1} alt="Portfolio Images"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Portfolio Details */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default PortfolioDetails1;
