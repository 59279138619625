import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/FooterTwo";
import {GoLinkExternal} from "react-icons/all";

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/" },
  { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/" },
  { Social: <FaTwitter />, link: "https://twitter.com/" },
];

import portfolio1 from "../assets/images/eriks/Dino1_1230.png";
import portfolio2 from "../assets/images/eriks/Bisti_Right_1230.jpg";
import portfolio3 from "../assets/images/eriks/dino2_1230.jpg";
import relatedImg1 from "../assets/images/portfolio/related-image-01.jpg";
import relatedImg2 from "../assets/images/portfolio/related-image-02.jpg";

class PortfolioDetails3 extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
        <React.Fragment>
          <PageHelmet pageTitle="Portfolio Details" />

          {/*<Header*/}
          {/*  headertransparent="header--transparent"*/}
          {/*  colorblack="color--black"*/}
          {/*  logoname="logo.png"*/}
          {/*/>*/}

          {/* Start Breadcrump Area */}
          <div
              className="rn-page-title-area pt--120 pb--190 bg_image bg_image--6"
              data-black-overlay="7"
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="rn-page-title text-center pt--100">
                    {/*<h2 className="title theme-gradient">*/}
                    {/*  PortFolio1*/}
                    {/*  */}
                    {/*</h2>*/}
                    {/*<p>*/}
                    {/*  Contrary to popular belief, Lorem Ipsum is not simply random*/}
                    {/*  text.{" "}*/}
                    {/*</p>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Breadcrump Area */}

          {/* Start Portfolio Details */}
          <div className="rn-portfolio-details ptb--120 bg_color--1">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="portfolio-details">
                    <div className="inner">
                      <h2>Bisti Beast</h2>
                      <br/>
                      <p>
                        Developed a complete project titled 'Bisti Beast' for the Albuquerque Museum of Natural 
                        History, focusing on a dinosaur exhibit.
                      </p>
                      <p>
                        It utilized Microsoft Kinect for real-time user tracking in front of a projected landscape 
                        featuring interactive roaming dinosaurs.
                      </p>
                      <p>
                        I also implemented an additional panel allowing users to select dinosaurs for interaction, 
                        integrating OSC signals to communicate with the Unreal Engine application.
                      </p>
                      <br/>
                      <br/>
                      <h4>Press</h4>
                      <li>
                        <ul>
                          <h5>
                            <a target="_blank" rel="noopener noreferrer"
                               href="https://www.krqe.com/news/albuquerque-metro/state-museum-gets-major-upgrades-while-closed-during-pandemic/">
                              KRQE <GoLinkExternal />
                            </a>
                          </h5>
                        </ul>
                      </li>

                      <div className="portfolio-view-list d-flex flex-wrap">
                        <div className="port-view">
                          <span>Role</span>
                          <h5>Lead Programmer / Developer</h5>
                        </div>
                        
                        <div className="port-view">
                          <span>Engine</span>
                          <h5>Unreal Engine 4</h5>
                        </div>

                        <div className="port-view">
                          <span>Project Type</span>
                          <h5>Interactive Dinosaur Display</h5>
                        </div>

                        <div className="port-view">
                          <span>Device</span>
                          <h5>Microsoft Kinect 2</h5>
                        </div>
                      </div>
                      <br/>

                      {/*<div className="portfolio-share-link mt--20 pb--70 pb_sm--40">*/}
                      {/*  <ul className="social-share rn-lg-size d-flex justify-content-start liststyle mt--15">*/}
                      {/*    {SocialShare.map((val, i) => (*/}
                      {/*        <li key={i}>*/}
                      {/*          <a href={`${val.link}`}>{val.Social}</a>*/}
                      {/*        </li>*/}
                      {/*    ))}*/}
                      {/*  </ul>*/}
                      {/*</div>*/}
                    </div>
                    <div className="portfolio-thumb-inner">
                      <div className="thumb position-relative mb--30">
                        <img src={portfolio3} alt="Portfolio Images"/>
                        <ModalVideo
                            channel="youtube"
                            isOpen={this.state.isOpen}
                            videoId="bK3RWWWftAk"
                            onClose={() => this.setState({ isOpen: false })}
                        />
                        <button
                            className="video-popup position-top-center"
                            onClick={this.openModal}
                        >
                          <span className="play-icon"></span>
                        </button>
                      </div>

                      <div className="thumb mb--30">
                        <img src={portfolio1} alt="Portfolio Images" />
                      </div>

                      <div className="thumb">
                        <img src={portfolio2} alt="Portfolio Images" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Portfolio Details */}

          {/* Start Back To Top */}
          <div className="backto-top">
            <ScrollToTop showUnder={160}>
              <FiChevronUp />
            </ScrollToTop>
          </div>
          {/* End Back To Top */}

          <Footer />
        </React.Fragment>
    );
  }
}
export default PortfolioDetails3;
